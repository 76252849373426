<template>
    <b-button :disabled="selection.selected_item_ids.length === 0 " variant="danger" @click="deleteItems" class="mr-1">
        <feather-icon
            icon="TrashIcon"
            class="mr-50"
        />
        <span class="align-middle">Delete items</span>
    </b-button>
</template>

<script>
    import { BButton} from 'bootstrap-vue'

    export default {
        props:{
            selection:{
                type:Object,
                required:true
            }
        },
        components: {
            BButton
        },
        methods:{
            async deleteItems() {

                const confirmDelete = await this.$confirmDialog(`Do you want to remove selected items? (${this.selection.selected_item_ids.length})`)
                if (!confirmDelete) {
                    this.$store.dispatch('app/updateSelectAll', false)
                    return this.$emit('reset')
                }


                try {
                    this.showLoader = true
                    await this.$http.delete('/api/management/v1/delete-selection/', {data: this.selection})
                    this.$printSuccess('Items removed')
                    this.$emit('deleted')
                    this.$emit('reset')
                    this.$store.dispatch('app/updateSelectAll', false)

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                } finally {
                    this.showLoader = false
                }
            }
        }
        
    }

</script>

<style scoped>

</style>